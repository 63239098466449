@tailwind base;
@tailwind components;

.tooltip {
    @apply invisible absolute;
}
  
.has-tooltip:hover .tooltip {
    @apply visible z-50;
}

@tailwind utilities;


@layer utilities {
    .no-arrows {

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            @apply appearance-none;
            margin: 0;
        }

        /* Firefox */
        &[type='number'] {
            @apply appearance-none;
        }
    }
}